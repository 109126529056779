main{
    /* background-color: #2E3B5D; */
    font-family: 'Montserrat', sans-serif;
   
}
h1{
    text-align: center;
    padding: 50px;
    color: var(--colorWhite);
   
}
.textoPB{
    width: 50%;
}
.principal{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px 20px;
    color: var(--colorWhite);
}

