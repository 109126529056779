body{
    background-image: url('./fondos/fondo-sm.jpg');
    background-size:cover;
    background-attachment: fixed;
}
.subtitle{
    padding-bottom: 40px;
}
@media(min-width:1400px){
    body{
        max-width: 100%;
        margin: auto;
    }
}
.subtitle{
    height: 70px;
    padding-left: 0px;
    background-position: bottom left;
}
.bg-mobile{
    position: fixed; 
    z-index: -1; 
    top: 0;
    width: 100vw;
}
@media(min-width:760px){
    body{
        background-image: url('./fondos/fondo-md.jpg');
        background-size:cover;
        background-attachment: fixed;
        
    }
    .bg-mobile{
        display: none;
    }
}
@media(min-width:1100px){
    body{
        background-image: url('./fondos/fondo-bg.jpg');
    }
    .subtitle{
        padding-left: 50px;
    }
}
