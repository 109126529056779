footer{
    height: 190px;
    justify-content: space-around;
    padding-bottom: 20px;
    padding-top:60px ;
    background: linear-gradient(transparent 0%,rgba(255, 255, 255,0.5)50%);
    font-size: 15px;
}

.fab, .far{
    font-size: 30px;
}

.fab:hover , .far:hover{
    transform: scale(2);
}
.redes{
    gap: 10px;
    font-size: 18px;
}
.redes>a, .location>a{
    color: #1c2439;
}
@media(max-width:900px){
    footer{
        height: fit-content;
        flex-direction: column;
        gap: 40px;
        font-size: 14px;
    }
}