main{
    /* background-color: #2E3B5D; */
    font-family: 'Montserrat', sans-serif;
   
}
h1{
    text-align: center;
    padding: 50px;
    color: var(--colorWhite);
   
}
.texto-p-aero {
    font-weight: 400;
    font-size: 20px;
}
.principal{
    padding: 50px 20px;
    color: var(--colorWhite);
}


