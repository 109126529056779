@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500&display=swap');
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
*{ 
  font-family:'Poppins', sans-serif;
   letter-spacing: 0.2em;
   font-size: 100;
   box-sizing: border-box;
}
:root{
  --colorBlue:#1c2439;
  --colorWhite:#ffffff;
  --spaceSection:200px;
}
iframe{
  width:100%;
  height:450px;
  border:"0"
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0,0,0,0.9)!important;
}
.w-80{
  width: 80%!important;
}

/* galery photos */
.container-images{
    width: 300px;
    height: 300px;
    transition: transform .5s;
}
.img-galeria{
    transform: scale(3);
}
.text-img{
    padding-top: 45%;
    background-color: rgb(0,0,0,0.7);
    backdrop-filter: blur(20px);
}
.container-images:hover>.text-img, .container-images:focus >.text-img{
    display: block!important;
} 

.scale-img{
    width:fit-content!important;
    z-index:1;
    transform:scale(1.5);
    transition:all .5s
}
.z-5{
  z-index: 5;
}

.max-80{
  max-width: 80%;
}
.container-images-ch{
  width: 300px;
  height: fit-content;
  max-height: 500px;
  transition: transform .5s;
}

@media(max-width:600px){
  .row,.carousel-inner{
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media(min-width:600px){
  iframe{
    width:600px;
  }
}