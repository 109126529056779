main{
    /* background-color: #2E3B5D; */
    font-family: 'Montserrat', sans-serif;
   
}
.img-home{
    height: 600px;
}
.cards-position{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin: 40px;
    gap:40px;

}


h2{
    margin-bottom: 100px;
    text-align: center;
    color:var(--colorWhite);
    font-size: 40px;
    padding-bottom: 0!important;
}
.carousel-home{
    height: fit-content;
    
}
.section-space{
    padding-top: var(--spaceSection)!important;
}    
.padding-bottom{
    padding-bottom: var(--spaceSection)!important;

}
.nuestrosV{
    padding-bottom: 35px;
}

h2 , h1,.prueba{
    display: flex;
    justify-content: center;
    
}
.cont{
    height: 600px;
    width: 100%;
    margin-bottom :100px ;    
}


.contenido{

    height: fit-content;    
    background-color: rgba(0, 0, 0, 0.5);
    color: var(--colorWhite);

}
.tehuelche{
    width: 100px;
}
.imagenPrueba{
    width: 50%;
    height: 300px;
}

.prueba{
    padding: 0px 40px;
}

.emotion1{
    padding-right:40px;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
h3{
    padding-top: 60px;
    padding-bottom: 40px;
    text-align: center;
    color: var(--colorWhite);
}

.text-about-us{
    padding: 0 40px;
    font-size: 18px;
    text-align: center;
    width: 80%;
    color: var(--colorWhite);

}

.us{
    /* background: url(../../../assets/img/nosotros2jpg.jpg) 50% 0 fixed; */
    background-size: cover;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin-top: 50px;
}


@keyframes titilar {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

.btn-primary{
    background-color: darkred;
    border: darkred;
    color: beige;
    animation-name: titilar;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

/*-------------media queries----------------*/
@media(min-width:1400px){
    .cards-position{
       justify-content: center;
       grid-template-columns: repeat(2,500px);
    }
}
@media (max-width:900px) {
    .cards-position{
        grid-template-columns: 1fr;
    }
    .title-h1{
        padding: 5px;
    }
        .text-home{
       font-size: 14px; 
    }
}
@media(max-width:500px){
    .title-h1{
        padding: 10px;
    
    }
.nuestrosV{
    font-size: calc(1.600rem + 1.5vw)!important
}
    .img-carousel{
        height: 400px;
    }
}