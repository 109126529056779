.fondo-modal{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0,0.7);
    backdrop-filter: blur(20px);
    z-index: 2;
}
.hide{
    display: none;
}
.card-modal{
    background-image: url('../../fondos/fondo-md.jpg') ;
    background-size: cover;
    border-radius: 8px;
}
.items-card{
    box-sizing: border-box;
    background-color: transparent;
    color: var(--colorWhite);
    text-transform: uppercase;
    font-weight: 300;
    font-size: 20px;
    border: var(--colorWhite) 1px solid;
    padding: 20px;
    border-radius: 8px;
}
.items-card:hover{
    background:linear-gradient(135deg,var(--colorBlue) 60%, #28324e 100%) ;
    border: none;
    cursor: pointer;
    font-weight: 200;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.options-lenguaje{
    list-style: none;
   gap: 80px;
}
@media(max-width:990px){
    .card-modal{
            width: 80% !important;
            height: 50% !important;
        }
}
@media (max-width:500px){
    .card-modal{
        height: 80% !important;
    }
    .options-lenguaje{
        flex-direction: column;
    }
}