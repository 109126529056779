#contact-us{
    padding-right: 40px;
}
.form{
    gap: 20px;
}

input{
    height: 40px;
    background-color: #FFFAF6;
}
.input-style{
    width: 100%;
    border:  2px solid #FFCFA3;
    background-color: transparent;
    border-radius: 10px;
}
label{
    width: 500px;
    gap: 20px;
    color: var(--colorBlue);
}
textarea{
    height: 300px;
}
.btn-outline-primary{
    padding: 20px;
    color: #1c2439;
    border: 3px solid #1c2439;
}

.btn-outline-primary:hover,.btn:first-child:active,.alert-mesage{
    background: #1c2439;
    color: #FFCFA3;
    border:2px solid #FFCFA3;
}
.text-popins{
    font-family:'Poppins', sans-serif;
    font-weight: 300;
    
}
.line{
    border-color: #1c2439;
    width: 40vw;
}
hr:not([size]){
    height: 2px;
}
.line-area{
    gap: 20px;
}
.btn-send{
    background: #1c2439;
    color: #FFCFA3;
    border-color: #FFCFA3;
}

.btn-send:hover{
    background: #FFCFA3;
    color: #1c2439;
}
@media(max-width:900px){
    .btn-outline-primary{
        width: 95% !important;
    }
    #contact-us{
        padding-right: 0px;
    }
    label{
        width: 100%;
        color: var(--colorWhite);
    }
    form{
        width: 80%;
    }
}