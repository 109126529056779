.title-card{
    font-size: 25px;
    width: 80%;
    transform: translateZ(60px);
    padding: 0;
}
.font-p{
    font-size: 14px;
}
.resume{
    text-align: center;
    transform: translateZ(60px);
}
.flip-container {
    perspective: 1000px;
    margin: auto;

}
	/* flip the pane when hovered */
	.flip-container:hover .flipper, .flip-container.hover .flipper {
		transform: rotateY(180deg);
	}

.flip-container, .front, .back {
    box-sizing: border-box;
    width: 500px;
	height: 380px;
}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;

	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
    transform-style: preserve-3d;
  
}

/* front pane, placed above back */
.front {
	z-index: 1;
	/* for firefox 31 */
    transform: rotateY(0deg);
    border-radius: 3%;
    color: white;
    display:flex;
    justify-content: center;
}

/* back, initially hidden pane */
.back {
    border-radius: 3%;
    transform: rotateY(180deg);
    color: black;
    padding: 30px 20px;
}
.locating{
    gap: 10px;
    transform: translateZ(60px);
}

.locating>a{
    background: #2E3B5D;
    border-radius: 8px;
    padding:10px;
    color: #FFCFA3;
    text-align: center;
    letter-spacing: 0;
}
.btn-card:hover{
    color: #2E3B5D;
    background-color: #FFCFA3;
   
}

.price{
    justify-self: flex-end;
}
.underline--magical{
    background-image: linear-gradient(120deg, #FE9F47 0%, #458589 100%);
    background-repeat: no-repeat;
    background-size: 30% 10px;
    background-position: 0 100%;

}


/*-------------media queries----------------*/

@media (max-width:1116px) {
    .flip-container,.front,.back{
        width: 100%;
    }
}
 @media (max-width:900px) {
    .flip-container,.front,.back{
        max-width: 500px;
        min-width:320px;
    }
} 
@media(min-width:900px){
    .font-p{
        font-size: 18px;
    }
}
