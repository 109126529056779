.nav-container{
    padding: 20px 0px;
}
nav{
    padding: 0px;
    height: fit-content;
    position: relative;
    /* background-color: #F8EFEA; */
}
.logo-img{
    background: transparent;
    width: 75%;
}
ul{
    display: flex;
    gap: 20px;
   padding: 0px;
}

.nav-item-link{
    /* color:#2D4440; */
    color: white;
    letter-spacing: 0.2em;
    font-weight: 300;
    font-size: 20px;
    height: 30px;
    background-image: linear-gradient(#1c2439 0%, #1c2439 100%);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;
}


.nav-item{
    height: 30px;
    padding-left: 15px;
}
.nav-item-link:hover{
    color: #1c2439;
    background-size: 100% 2px;
}
.hamb{
    display: none;
    font-size: 2rem;
    color: var(--colorWhite);
    transition: all .5s;
}

@media (max-width:1000px){
    .nav{
        flex-wrap: nowrap;
        text-align: center;
        align-items: center;
        
    }
    .nav-container{
        max-width: 100%;
    }
    .nav-item-link{
        font-weight: bold;
    }
    .bg-small{
        background-image: url('../../fondos/fondo-sm.jpg');
        background-size: 100%;
    }
}

@media (max-width:760px){
    .hamb{
        display: block;
    }
    
    .nav-item{
        box-sizing: border-box;
    }
    
    .nav{
        background-image:url(../../fondos/fondo-sm.jpg) ;
      background-size: cover;
        display: none;
        flex-direction: column;
        justify-content: space-around;
        position: absolute;
        top:90% ;
        left: 0%;
        width: 100vw;
        height: 90vh;
        z-index: 4;
        padding: 5px 5px;
    }
    .nav-item-link{
        font-size: 1.7em;
        font-weight:normal;
        background-image: linear-gradient(white 0%, white 100%);
    }
    .nav-item-link:hover{
        color: white;
    }
}

	

