main{
    /* background-color: #2E3B5D; */
    font-family: 'Montserrat', sans-serif;
   
}
h1{
    text-align: center;
    padding: 50px;
    color: var(--colorWhite);
}
.textoPB{
    width: 50%;
}
.principal{
    padding: 50px 20px;
    color: var(--colorWhite);
}
.list-recomendations{
    list-style-image: url(../../fondos/check-icon.svg);
    grid-template-columns: repeat(2,300px);
    column-gap: 20%;

}

