.text{
    color: var(--colorWhite);
    padding: 0 40px ;
}

/* .info-vehicles,#carouselVehicules{
    width: 70%;
    
} */
.bottom-vol{
    bottom: 10%;
}
.item-list{
    list-style: none;
    height: 80%;
    font-size: 20px;
   
}

.item-vehiculos{
    padding: 10px;
}

.title-vehicles{
    text-shadow:3px 3px var(--colorBlue); 
}
.section-vehicles{
    height: calc(206px + 2rem);
}

.box-vehicles-type{
    height: 205px;

}

@media(max-width: 900px){
    .section-vehicles {
        flex-direction: column;
        height: fit-content;
    }
    .info-vehicles,#carouselVehicules{
        width: 100%;
        
    }
    .item-list{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        justify-self: center;
        margin: auto;
        padding-top: 20px;
    }
    .item-vehiculos{
        position: static;
    }
    .auto{
        align-self: flex-start;
    }
    .auto-h1{
        align-self: flex-end;
    }

}
@media (max-width:1190px){
    .item-list{
        flex-direction: column;
    }
}